import types from './constants';

export function logoutSaga() {
    return {
        type: types.LOGOUT_SAGA,
    };
}

export function authorizeUser() {
    return {
        type: types.AUTH_SAGA,
    };
}

export function setUser(user) {
    return {
        type: types.SET_USER,
        user,
    };
}

export function setModalData(data) {
    return {
        type: types.SET_MODAL_DATA,
        data,
    };
}
