import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

import blue from '@material-ui/core/colors/blue';
import blueGrey from '@material-ui/core/colors/blueGrey';
import red from '@material-ui/core/colors/red';

import logo from './logo.svg';
import './App.css';

import Routes from './Routes';

const palette = {
  primary: {
      main: blueGrey[800],
      light: blueGrey[600],
  },
  secondary: {
      main: blue[50],
      dark: blue[100],
  },
  error: {
      main: red.A400,
  },
  background: {
      default: '#fff',
  },
};

const breakpoints = createBreakpoints({});

const theme = createMuiTheme({
  typography: {
      fontFamily: [
          "'Oswald'",
          "'Lato'",
          "'Roboto'",
          'sans-serif',
      ].join(', '),
  },
  palette: palette,
  header: {
      color: palette.primary.main,
      textAlign: 'center',
      position: 'relative',
      '&:before': {
          float: 'left',
          content: '" "',
          display: 'inline-block',
          height: '2px',
          margin: '0 10px',
          position: 'absolute',
          top: '50%',
          right: '100%',
          width: '80px',
          overflow: 'auto',
          zoom: '1',
          background: palette.primary.main,
          [breakpoints.down('xs')]: {
              width: 'auto',
          },
      },
      '&:after': {
          content: '" "',
          display: 'inline-block',
          height: '2px',
          margin: '0 10px',
          position: 'absolute',
          top: '50%',
          left: '100%',
          overflow: 'auto',
          width: '80px',
          marginLeft: '5px',
          zoom: '1',
          background: palette.primary.main,
          [breakpoints.down('xs')]: {
              width: 'auto',
          },
      }
  },
  light: {
      color: palette.secondary.main,
      '&:before': {
          backgroundColor: palette.secondary.main
      },
      '&:after': {
          backgroundColor: palette.secondary.main
      },
  },
});

function App() {
  return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </BrowserRouter>
  );
}

export default App;
