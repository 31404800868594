import cx from 'classnames';
import * as React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        //loadingpage centered
    },
    full: {
        //loadingpage--full
    }
}));

const Loading = React.memo(
    ({ text, fullPage = true }) => {
        const classes = useStyles();
        return (
            <div className={cx(classes.root, { [classes.full]: fullPage })}>
                <Typography>{text}</Typography>
                <CircularProgress />
            </div>
        );
    }
);

export default Loading;
