import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import Layout from './components/layouts/Layout';
import Loading from './pages/Loading';

const Home = lazy(() => import('./pages/Home'));
const NotFound = lazy(() => import('./pages/404'));

const Routes = React.memo(() => {
    return (
        <Layout>
            <Suspense fallback={<Loading />}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/dev/auth/epic/return" component={(props) => { window.location = "http://localhost:8000/auth/epic/return" + props.location.search; return null; }} />

                    <Route component={NotFound} />
                </Switch>
            </Suspense>
        </Layout>
    );
});

export default Routes;
