import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/es/storage' // default: localStorage if web, AsyncStorage if react-native

import createSagaMiddleware from 'redux-saga'
import reducers from '../reducers'
import rootSaga from './rootSaga'

const config = {
    key: 'root',
    storage,
    blacklist: [],
};

const logger = store => next => action => {
    console.group(action.type);
    console.info('dispatching', action);
    let result = next(action);
    console.log('next state', store.getState())
    console.groupEnd();
    return result
};

const reducer = persistCombineReducers(config, reducers);
const sagaMiddleware = createSagaMiddleware();

let middleware = process.env.NODE_ENV === 'development'
        ? [ sagaMiddleware, logger ]
        : [ sagaMiddleware ];

const storeCompose = compose(applyMiddleware(...middleware));

const configureStore = () => {
    const store = createStore(reducer, storeCompose)

    const persistor = persistStore(store, null, () => {
        store.getState()
    })
    sagaMiddleware.run(rootSaga)
    return { persistor, store }
};

export default configureStore;
