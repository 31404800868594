import { takeEvery, call, put } from 'redux-saga/effects';

import types from '../constants';
import { setUser } from '../actions';
import PointBlankAPI from '../../../constants/api';

export function* authSaga() {
    try {
        const user = yield call(PointBlankAPI.authorize);
        yield put(setUser(user));
    } catch (e) {
        console.error('Auth Error:', e);
    }
}

export default function* watch() {
    yield takeEvery(types.AUTH_SAGA, authSaga);
}
