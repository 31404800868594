import { takeEvery, call, put } from 'redux-saga/effects';

import types from '../constants';
import tournamentAPI from '../../../constants/api';
// import axios from '../../../constants/axios';
import { setUser } from '../actions';

export function* logoutSaga() {
    try {
        yield call(tournamentAPI.logout);
    } catch (e) {
        console.log(e);
    } finally {
        yield put(setUser(undefined));
    }
}

export default function* watch() {
    yield takeEvery(types.LOGOUT_SAGA, logoutSaga);
}
