import Axios from 'axios';

import config from './config';

const axios = Axios.create({
    baseURL: config.apiURL,
    withCredentials: true,
});

class PointBlankAPI {

    async authorize() {
        try {
            const { data: user } = await axios.get('/auth');

            return user._id ? user : undefined;
        } catch (error) {
            return;
        }
    }

    async logout() {
        try {
            await axios.get('/logout');
        } catch (error) {
            console.error('Logout error:', error);
        }
    }

}

const POINT_BLANK_API = new PointBlankAPI();
export default POINT_BLANK_API;