const getAPIUrl = () => {
    switch (window.location.hostname) {
        case 'highground.gg':
            return 'https://highground.gg/api';
        case 'localhost:3000':
        default:
            return 'http://localhost:8000';
    }
};

function generateConfig() {
    return {
        apiURL: getAPIUrl(),
    };
}

const config = generateConfig();

export default config;
