import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './Header';
// import Footer from './Footer';
//import Modal from './Modal';
//import BurgerMenu from './BurgerMenu';
import ErrorBoundary from '../ErrorBoundary';
//import UserTeamInvitations from '../blocks/UserTeamInvitations';
//import LoggedInWrapper from '../../pages/Wrappers/LoggedInWrapper';

const MainLayout = React.memo(({ children }) => {
    return (
        <>
            <ToastContainer closeButton={<React.Fragment />} autoClose={4000} />
            <Header />
            {/*<BurgerMenu />*/}
            <ErrorBoundary>
                {/*<LoggedInWrapper noRender>
                    <UserTeamInvitations />
                </LoggedInWrapper>*/}
                <main>{children}</main>
                {/*<Modal />*/}
            </ErrorBoundary>
            {/* <Footer /> */}
        </>
    );
});

export default MainLayout;
