import * as React from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faFrown } from '@fortawesome/pro-solid-svg-icons';
//import { Button } from 'reactstrap';

const clearCache = () => {
    localStorage.removeItem('persist:root');
};

class ErrorBoundary extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
    }

    render() {
        const { hasError } = this.state;
        if (hasError) {
            return (
                <div className="error centered">
                    {/*<FontAwesomeIcon icon={faFrown} />
                    It looks like something went wrong! <br /> Try refreshing
                    the page and if the error persists contact an admin
                    <Button
                        style={{ width: '200px', marginTop: '30px' }}
                        onClick={clearCache}
                    >
                        Clean Cache
                    </Button>*/}
                    <span className="text-error" style={{ fontSize: '12px' }}>
                        This will clear your local storage of any site data
                    </span>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
