import * as React from 'react';
import { connect } from 'react-redux';
//import { faDiscord } from '@fortawesome/free-brands-svg-icons';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sits_small from '../../../assets/sits_small.png';

import config from '../../../constants/config';
import { selectUser } from '../../../reducers/app/selectors';
import { authorizeUser } from '../../../reducers/app/actions';
//import NavDropdown from './NavDropdown';
//import UserMenu from './UserMenu';
import User from '../../blocks/User';
import Loading from '../../../pages/Loading';

const LoginButton = React.memo(({ user, auth }) => {
    const [authorizing, setAuthorizing] = React.useState(true);

    React.useEffect(() => {
        auth();
        setAuthorizing(false);
    }, [auth]);

    return authorizing ? (
        <div className="header__login centered">
            <Loading />
        </div>
    ) : (
        <div className="header__login" id="login">
            {user ? (
                <>
                    {/*<NavDropdown size={1} dropdownMenu={<UserMenu />}>
                        <User user={user} />
                    </NavDropdown>*/}
                </>
            ) : (
                <a href={`${config.apiURL}/login`} className="centered">
                    <img src={sits_small} /> Login
                </a>
            )}
        </div>
    );
});

const mapStateToProps = (state) => ({
    user: selectUser(state),
});

const mapDispatchToProps = (dispatch) => ({
    auth: () => dispatch(authorizeUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginButton);
