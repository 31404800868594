/* eslint-disable import/no-named-as-default */
import { all, fork } from 'redux-saga/effects';

import logoutSaga from '../reducers/app/sagas/logoutSaga';
import authSaga from '../reducers/app/sagas/authSaga';

export default function* rootSaga() {
    yield all([
        fork(logoutSaga), 
        fork(authSaga)
    ]);
}
