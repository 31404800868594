import * as React from 'react';
import Avatar from '@material-ui/core/Avatar';

const User = React.memo(({ user, fill = false }) => {
    if (!user) {
        return (
            <div
                className={`user__container ${
                    fill ? 'user__container--fill' : ''
                }`}
            >
                <div
                    className="user__username text-faded"
                    style={{ fontSize: '14px' }}
                >
                    User not found
                </div>
            </div>
        );
    }

    return (
        <div
            className={`user__container ${fill ? 'user__container--fill' : ''}`}
        >
            <Avatar alt={user.name} src={user.photos[0]} />
            <div className="user__username">
                {user.name}
            </div>
        </div>
    );
});

export default User;
