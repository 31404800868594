import * as React from 'react';
// import { Link } from 'react-router-dom';
/*import {
    faTrophy,
    // faChess,
    faWrench,
    faHome,
} from '@fortawesome/pro-solid-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';*/
import Container from '@material-ui/core/Container';

// import Logo from '../../images/Logo.png';

//import NavButton from './NavButton';
//import NavDropdown from './NavDropdown';
import LoginButton from './nav/LoginButton';
//import AdminWrapper from '../../pages/Wrappers/AdminWrapper';

// const TournamentDropdown = React.memo(() => (
//     <React.Fragment>
//         <NavButton route="/tournaments/tft" icon={faChess}>
//             TFT
//         </NavButton>
//         <NavButton route="/tournaments/tft" icon={faChess}>
//             TFT
//         </NavButton>
//         <NavButton route="/tournaments/tft" icon={faChess}>
//             TFT
//         </NavButton>
//     </React.Fragment>
// ));

const AdminPortalMenu = React.memo(() => (
    <React.Fragment>
        {/*<NavButton route="/admin/tournaments" icon={faTrophy}>
            Tournaments
        </NavButton>*/}
    </React.Fragment>
));

const Header = React.memo(() => {
    return (
        <header>
            <Container>
                {/* <div
                    style={{
                        height: '100%',
                        display: 'inline-block',
                        marginRight: '10px',
                    }}
                >
                    <Link to="/">
                        <div className="logo centered">
                            <img src={Logo} alt="Team Liquid Horse" />
                            Liquid Tournaments
                        </div>
                    </Link>
                </div> */}

                <nav>
                    {/*<NavButton exact route="/" icon={faHome}>
                        Home
                    </NavButton>
                    <NavDropdown size={1} dropdownMenu={<TournamentDropdown />}>
                        <NavButton
                            dropdown
                            route="/tournaments"
                            icon={faTrophy}
                        >
                            Tournaments
                        </NavButton>
                    </NavDropdown>
                    <AdminWrapper requiredAccess={2} show={false}>
                        <NavDropdown
                            size={1}
                            dropdownMenu={<AdminPortalMenu />}
                        >
                            <NavButton
                                dropdown
                                route="/admin/tournaments"
                                icon={faWrench}
                            >
                                Admin Portal
                            </NavButton>
                        </NavDropdown>
                    </AdminWrapper>
                    <NavButton
                        external
                        route="https://discord.com"
                        icon={faDiscord}
                    >
                        Get Discord
                    </NavButton> */}
                </nav>
                <LoginButton />
            </Container>
        </header>
    );
});

export default Header;
