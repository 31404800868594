import { types } from './constants';
import { REHYDRATE } from 'redux-persist';

// Initial State
export const initialState = {
    user: undefined,
    modal: {
        data: undefined,
    },
};

function appReducer(state = initialState, action) {
    switch (action.type) {
        case REHYDRATE: {
            const app =
                action.payload && action.payload.app
                    ? action.payload.app
                    : initialState;
            return {
                ...initialState,
                ...app,
                loggingIn: false,
                loginModalPage: 'login',
                deckModalOpen: false,
                loginModalOpen: false,
            };
        }

        case types.SET_USER: {
            const { user } = action;

            return {
                ...state,
                user,
            };
        }

        case types.SET_MODAL_DATA: {
            const { data } = action;

            return {
                ...state,
                modal: {
                    ...state.modal,
                    data,
                },
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
}

export default appReducer;
